import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import store from "@/state/store";
import CKEditor from "@ckeditor/ckeditor5-vue";
import BootstrapVueNext from "bootstrap-vue-next";
import { initFirebaseBackend } from "./helpers/firebase/authUtils";
import { configureFakeBackend } from "./helpers/fakebackend/fake-backend";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
  configureFakeBackend();
}

const originalConsoleError = console.error
console.error = function (...args) {
    if (args[0] && typeof args[0] === 'string' && args[0].includes('ERR_CONNECTION_REFUSED')) {
        // Suppress ERR_CONNECTION_REFUSED errors
        return;
    }
    originalConsoleError.apply(console, args);
};


import "@/assets/scss/app.scss";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import 'bootstrap/dist/js/bootstrap.js';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import eventBus from './plugins/event-bus';

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App);
app.config.globalProperties.global_division = reactive({content: '', percent: 100, type: '', company: '', logo: '', profile: "", email: "",});
app.use(store);
app.use(router);
app.use(BootstrapVueNext);
app.use(i18n);
app.use(CKEditor);
app.use(vuetify);
app.use(eventBus)
app.mount("#app");